import { differenceInMilliseconds } from 'date-fns';
import { FC, useEffect, useState } from 'react';

type Countdown = {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
};

const TIME_ONE_SECOND = 1000;

const getCountdown = (totalMilliseconds: number): Countdown | null => {
  if (totalMilliseconds <= 0) {
    return { days: '00', hours: '00', minutes: '00', seconds: '00' };
  }

  const totalSeconds = Math.floor(totalMilliseconds / 1000);

  const days = Math.floor(totalSeconds / (3600 * 24))
    .toString()
    .padStart(2, '0');
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((totalSeconds % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (totalSeconds % 60).toString().padStart(2, '0');

  return { days, hours, minutes, seconds };
};

const CountdownTimerBox: FC<{ value: string; text?: string; hasSeparator?: boolean; hasFixedWidth?: boolean }> = ({
  value,
  text,
  hasSeparator,
  hasFixedWidth,
}) => {
  const fixedWitdhClass = hasFixedWidth ? 'min-w-[46.29px]' : '';

  return (
    <>
      <div className="flex flex-col items-center gap-1 ml-2">
        <div className={`flex items-center ${fixedWitdhClass} `}>
          <span className="text-[#8BE737] text-[34px] leading-[34px] font-extrabold">{value}</span>
        </div>
        <span className="text-white text-[8px] leading-[9.68px]">{text}</span>
      </div>
      {hasSeparator && <span className="text-[#8BE737] text-[30px] leading-[30px] font-extrabold">:</span>}
    </>
  );
};

const countDownIsOver = (countDown: Countdown) => Object.values(countDown).every((value) => value === '00');

const CountdownTimer: FC<{
  endDate: Date | null;
  daysLabel: string;
  dayLabel: string;
  hoursLabel: string;
  minutesLabel: string;
  secondsLabel: string;
  hideEndCounter?: boolean;
  onHideCounter?: VoidFunction;
}> = ({ endDate, daysLabel, dayLabel, hoursLabel, minutesLabel, secondsLabel, hideEndCounter, onHideCounter }) => {
  const [countDown, setCountDown] = useState<Countdown | null>(null);

  useEffect(() => {
    if (!endDate) {
      return;
    }

    const timer = setInterval(() => {
      const totalMilliseconds = differenceInMilliseconds(endDate, new Date());
      setCountDown(getCountdown(totalMilliseconds));

      if (totalMilliseconds <= 0) {
        clearInterval(timer);
      }
    }, TIME_ONE_SECOND);

    return () => clearInterval(timer);
  }, [endDate]);

  if (!endDate || !countDown) {
    return <div className="h-[47px]" />;
  }

  if (countDown && countDownIsOver(countDown) && hideEndCounter) {
    onHideCounter?.();
    return null;
  }

  const { days, hours, minutes, seconds } = countDown;

  const daysText = days === '01' ? dayLabel : daysLabel;

  return (
    <div className="flex flex-row gap-1 h-[47px]">
      <CountdownTimerBox value={days} text={daysText} />
      <CountdownTimerBox value={hours} text={hoursLabel} hasSeparator />
      <CountdownTimerBox value={minutes} text={minutesLabel} hasSeparator />
      <CountdownTimerBox value={seconds} text={secondsLabel} hasFixedWidth />
    </div>
  );
};

export { CountdownTimer };
